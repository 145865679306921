import React from "react";
import MainLayout from "../components/MainLayout";
import FeatureLayout from "../components/FeatureLayout/FeatureLayout";

const feature = {
  id: "360-models",
  image: "/images/showcase/feature-interactivity.png",
  title: "360° Models",
  description: (
    <p>
      3D models allow your costumers to experience the product as if they were
      in rea store.
      <br />
      <br />
      Touching, turning and zooming the product creates a better connection
      which icnreases your conversion rate.
    </p>
  ),
  ctaText: "Try it out",
  nextRoute: "/interactivity",
  width: 25
};
const Three60Models = () => {
  return <FeatureLayout {...feature} />;
};

Three60Models.Layout = MainLayout;

export default Three60Models;
